import React from 'react';
import { PageContainer } from '../../../ux/page-container';
import { Typography } from '@hiyllo/ux/typography';
import { Select } from '@hiyllo/ux/select';
import { DatabaseHostingLocationEnum, DatabaseKindEnum } from '../../../../types/infrastructure/database';
import { Input } from '@hiyllo/ux/input';
import { Button } from '@hiyllo/ux/button';

import * as PutDatabaseBP from '../../../../blueprints/infrastructure/put-database';
import * as ProvisionDatabaseBP from '../../../../blueprints/infrastructure/provision-database';
import { seamlessClient } from '../../../../seamless';
import { MoopsyError } from "@moopsyjs/core";
import { FlexRow } from '../../../ux/flex-row';
import { useNavigate } from 'react-router-dom';
import { DigitalOceanDatabaseSizeSlug } from '../../../../types/omni/infrastructure';
import { useAlertDialog } from '@hiyllo/ux/dialogs';

const PutDatabaseForm = React.memo(function PutDatabaseForm (): JSX.Element {
  const navigate = useNavigate();

  const putDatabaseMutation = seamlessClient.useMutation<PutDatabaseBP.Plug>(PutDatabaseBP);

  const [name, setName] = React.useState<string>('');
  const [digitalOceanUUID, setDigitalOceanUUID] = React.useState<string>('');

  const onSubmit = React.useCallback(() => {
    putDatabaseMutation.call({
      name,
      hosting: {
        location: DatabaseHostingLocationEnum.digitalOcean,
        uuid: digitalOceanUUID
      }
    }).then((res) => {
      navigate('/database/' + res.uuid);
    }).catch(err => {
      alert((err as MoopsyError).message);
    });
  }, [putDatabaseMutation, name, digitalOceanUUID, navigate]);

  return (
    <div>
      <Typography.Label>Name</Typography.Label>
      <Input
        value={name}
        onChangeValue={(value) => setName(value)}
      />
      <div style={{ height: 16 }}/>
      <Typography.Label>Digital Ocean UUID</Typography.Label>
      <Input
        value={digitalOceanUUID}
      onChangeValue={(value) => setDigitalOceanUUID(value)}
      />
      <div style={{ height: 16 }}/>
      <FlexRow>
        <Button
          label="Submit"
          onClick={onSubmit}
          isLoading={putDatabaseMutation.isLoading}
        />
      </FlexRow>
    </div>
  );
});

const ProvisionDatabaseForm = React.memo(function ProvisionDatabaseForm (): JSX.Element {
  const navigate = useNavigate();

  const provisionDatabaseMutation = seamlessClient.useMutation<ProvisionDatabaseBP.Plug>(ProvisionDatabaseBP);

  const [name, setName] = React.useState<string>('');
  const [kind, setKind] = React.useState<DatabaseKindEnum | null>(null);
  const showAlert = useAlertDialog();

  const onSubmit = React.useCallback(() => {
    if(kind == null) {
      void showAlert({
        title: 'Error',
        message: 'Please select a kind',
      });
      return;
    }

    provisionDatabaseMutation.call({
      name,
      variant: kind,
      size: DigitalOceanDatabaseSizeSlug.db1VCPU1GB,
      hostLocation: DatabaseHostingLocationEnum.digitalOcean
    }).then((res) => {
      navigate('/operation/' + res.operationUUID + "?returnTo=" + encodeURIComponent('/database/' + res.databaseUUID));
    }).catch(err => {
      alert((err as MoopsyError).message);
    });
  }, [kind, provisionDatabaseMutation, name, showAlert, navigate]);

  return (
    <div>
      <Typography.Label>Kind</Typography.Label>
      <Select
        value={kind}
        onChangeValue={(value) => setKind(value as DatabaseKindEnum)}
        options={[
          {
            value: null,
            label: 'Select Kind...'
          },
          {
            value: DatabaseKindEnum.mongo,
            label: 'Mongo'
          },
          {
            value: DatabaseKindEnum.postgres,
            label: 'Postgres'
          },
          {
            value: DatabaseKindEnum.redis,
            label: 'RedisKV'
          },
        ]}
      />
      <div style={{ height: 16 }}/>
      <Typography.Label>Name</Typography.Label>
      <Input
        value={name}
        onChangeValue={(value) => setName(value)}
      />
      <div style={{ height: 16 }}/>
      <FlexRow>
        <Button
          label="Submit"
          onClick={onSubmit}
          isLoading={provisionDatabaseMutation.isLoading}
        />
      </FlexRow>
    </div>
  );
});

type ModeType = 'provision' | 'put' | null;
export const CreateDatabase = React.memo(function CreateDatabase (): JSX.Element {
  const [mode, setMode] = React.useState<ModeType>(null);

  return (
    <PageContainer>
      <Typography.Header>Create Database</Typography.Header>
      <div style={{ height: 16 }}/>
      <Select
        value={mode}
        onChangeValue={(value) => setMode(value as ModeType)}
        options={[
          {
            value: null,
            label: 'Select Mode...'
          },
          {
            value: 'provision',
            label: 'Provision (Create a new database)'
          },
          {
            value: 'put',
            label: 'Put (Onboard existing database)'
          }
        ]}
      />
      <div style={{ height: 16 }}/>
      {mode === null ? null : mode === 'put' ? <PutDatabaseForm/> : <ProvisionDatabaseForm/>}
    </PageContainer>
  );
});
