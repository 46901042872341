import React from 'react';
import { Button } from '@hiyllo/ux/button';

import * as ListFilestoresBP from '../../../../blueprints/infrastructure/list-filestores';
import { PageContainer } from '../../../ux/page-container';
import { FlexRow } from '../../../ux/flex-row';
import { useNavigate } from 'react-router-dom';
import { seamlessClient } from '../../../../seamless';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import { Typography } from '@hiyllo/ux/typography';

export const FilestoresList = React.memo(function FilestoresList (): JSX.Element {
  const navigate = useNavigate();
  const query = seamlessClient.useQuery<ListFilestoresBP.Plug>(ListFilestoresBP, null);

  return (
    <PageContainer>
      <FlexRow>
        <Typography.Header>Filestores</Typography.Header>
        <div style={{flexGrow:1}}/>
        <Button
          label="Create Filestore"
          onClick={() => {
            navigate('/filestores/create');
          }}
        />
      </FlexRow>
      {query.isLoading ? <LoadingSpinner/> : query.isError ? <div>{query.error.message}</div> : (
        <div style={{display:"flex", flexDirection:"column", paddingTop:16, paddingBottom:16, gap:8}}>
          {query.data.filestores.map((filestore) => (
            <FlexRow key={filestore.uuid} style={{cursor:"pointer"}} onClick={() => {
              navigate('/filestore/' + filestore.uuid);
            }}>
              <div>{filestore.name}</div>
            </FlexRow>
          ))}
        </div>
      )}
    </PageContainer>
  );
});
