import React from "react";
import { PageContainer } from "../../../ux/page-container";

import * as GetOperationBP from '../.././../../blueprints/infrastructure/get-operation';
import { seamlessClient } from "../../../../seamless";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@hiyllo/ux/typography";
import { OperationStatusEnum } from "../../../../types/infrastructure/operation";
import { FlexRow } from "../../../ux/flex-row";
import moment from "moment";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { Button } from "@hiyllo/ux/button";
import { Card } from "@hiyllo/ux/surface";

const StatusIndicator = React.memo(function StatusIndicator ({status}:{status:OperationStatusEnum}): JSX.Element {
    if(status === OperationStatusEnum.IN_PROGRESS) {
        return (
            <LoadingSpinner/>
        );
    }

    if(status === OperationStatusEnum.SUCCEEDED) {
        return (
            <FontAwesomeIcon icon={faCheckCircle} style={{fontSize:16}} color="#4caf50"/>
        );
    }

    if(status === OperationStatusEnum.FAILED) {
        return (
            <FontAwesomeIcon icon={faCircleXmark} style={{fontSize:16}} color="red"/>
        );
    }

    return (
      <div
      style={{
        height: 16,
        width: 16,
        borderRadius: '50%',
        background: 'grey'
      }}
      />
    );
});

export const OperationView = React.memo(function OperationView (): JSX.Element {
    const navigate = useNavigate();
    const { uuid } = useParams<{uuid:string}>();

    if(uuid == null) {
        throw new Error('uuid is required');
    }
    
    const operationQuery = seamlessClient.useQuery<GetOperationBP.Plug>(GetOperationBP, { uuid });

    React.useEffect(() => {
        const inv = setInterval(() => {
            void operationQuery.refresh({subtle:true});
        }, 2500);

        return () => {
            clearInterval(inv);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const returnPage = React.useCallback(() => {
        const returnTo = new URLSearchParams(window.location.search).get('returnTo');
        navigate(returnTo ?? '/');
    }, []);

    if(operationQuery.isLoading) {
        return (
            <PageContainer>
                Loading...
            </PageContainer>
        );
    }

    if(operationQuery.isError) {
        return (
            <PageContainer>
                {operationQuery.error.message}
            </PageContainer>
        );
    }

    const { operation } = operationQuery.data;

    return (
        <PageContainer>
            <Typography.Header>{operation.label}</Typography.Header>
            <div style={{display:"flex", flexDirection:"column", gap:16, paddingTop:16}}>
                {operation.steps.map(step => (
                    <FlexRow key={step.uuid} style={{gap:16, fontWeight:step.status === OperationStatusEnum.IN_PROGRESS ? 'bold' : 'normal'}}>
                        <StatusIndicator status={step.status}/>
                        <div>{step.label}</div>
                        {step.status !== OperationStatusEnum.PENDING ? <div>
                            Started: {moment(step.started).format('hh:mm:ss a')}
                        </div> : null}
                        {step.status === OperationStatusEnum.SUCCEEDED ? <div>
                            Finished: {moment(step.finished).format('hh:mm:ss a')}
                        </div> : null}
                    </FlexRow>
                ))}
                
                {operation.failureLogs != null ?
                    <>
                        <Card color="background3">
                            <div style={{whiteSpace:"pre-wrap"}}>
                                {operation.failureLogs}
                            </div>
                        </Card>
                    </>    
                : null}

                <FlexRow>
                    {(operation.status === OperationStatusEnum.SUCCEEDED || operation.status === OperationStatusEnum.FAILED) ?
                        <Button
                            onClick={returnPage}
                            label="Return"
                        />
                    : null}
                </FlexRow>
            </div>
        </PageContainer>
    );
});